import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Clamp from 'react-multiline-clamp';

import {
  Button,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import {
  East,
  PlayCircleRounded,
  VisibilityOutlined,
} from '@mui/icons-material';

import store from '../utilities/store';
import useQuery from '../utilities/useQuery';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import AndroidService from '../services/AndroidService';
import CropInfoService from '../services/CropInfoService';
import ClevertapService from '../services/ClevertapService';
import { allStates, getAppSource, getStateBySlug } from '../utilities/states';
import { getRouteParams, makeExpertVideosHref } from '../utilities/links';
import { CropInfoSkeleton } from './CropsTabs';

const ExpertVideoPageWrapper = styled('div')({
  padding: '0 15px',
  minHeight: '300px',
  maxHeight: '300px',
  background: '#fff',
});
const VideoViewsWrapper = styled('div')({
  position: 'absolute',
  top: '58px',
  left: '4px',
  padding: '3px 5px',
  opacity: '0.8',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  background: '#fff',
});
export const SubHeadingWrapper = styled(Typography)({
  padding: '15px 16px 0',
  fontSize: '18px',
  fontWeight: 700,
});
const PlayIcon = styled(PlayCircleRounded)({
  width: '30px',
  height: '30px',
  position: 'absolute',
  top: '20%',
  left: 'calc(50% - 30px/2)',
  color: '#fff',
  zIndex: 1,
});
export const ViewAllButton = styled(Button)({
  fontSize: '14px',
  fontWeight: 700,
  borderRadius: '8px',
  '&:visited': { color: 'rgb(155,31,36)' },
  margin: '8px 16px 0',
  width: 'calc(100% - 32px)',
  background: '#fff',
  '&:hover': { background: '#fff' },
});

function ExpertVideos({ setHeaderURLs, intl }) {
  const { loadingStatus, match, search } = useFetchData(
    ExpertVideos,
    store,
    setHeaderURLs,
    intl,
  );
  const query = useQuery();
  const farmerAuthToken = query.get('farmerAuthToken');
  const { slug, state } = getRouteParams(match, search);
  const [videos, setVideos] = useState(null);
  const [loading, setLoading] = useState('started');

  useEffect(() => {
    CropInfoService.getGoldVideos({ cropName: slug, state, farmerAuthToken })
      .then((response) => {
        setVideos(response?.responseData?.videos);
        setLoading('done');
      })
      .catch((e) => {
        console.log('Failed to fetch Videos: ', e);
        setLoading('error');
      });
  }, []);

  if (loading === 'error') return null;
  if (loadingStatus === 'loading' || loading === 'started') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  return (
    <ExpertVideoPageWrapper>
      <GoldVideo videos={videos} cropName={slug} />
    </ExpertVideoPageWrapper>
  );
}

export function GoldVideo({
  loading,
  videos,
  index,
  cropName,
  displayCropName = '',
}) {
  const query = useQuery();
  const appLinks = query.get('appLinks');
  const state = query.get('state');
  const mobile = query.get('mobile');
  const source = query.get('source') || getAppSource(state);
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      setIsAndroid(true);
    }
  }, []);

  const handleVideoClick = (video) => {
    CropInfoService.ping();
    ClevertapService.pushEvent(`Card Clicked`, {
      Type: 'Play Gold Video',
      'Video Id': video?.youTubeId,
    });
    window.parent.location.href = `https://gold.agrostar.in/crop/${cropName}/video/${video?.youTubeId}?mobile=${mobile}&source=${source}&utm_source=as_embedded_app_croppage`;
  };

  const handleViewAllClick = () => {
    ClevertapService.pushEvent(`Button Clicked`, {
      Type: 'View All Gold Videos',
    });
    window.parent.location.href = `https://gold.agrostar.in/crop-videos/${cropName}?mobile=${mobile}&source=${source}&utm_source=as_embedded_app_croppage`;
  };

  if (loading === 'skeleton' || (!videos && index !== 0))
    return <CropInfoSkeleton />;
  if (!videos?.length) return null;

  return (
    <>
      <SubHeadingWrapper>
        <FormattedMessage id="label_watch_expert_videos" />
      </SubHeadingWrapper>

      <Grid
        container
        flexWrap="nowrap"
        gap="10px"
        sx={{
          mt: '6px',
          p: '2px 16px',
          overflowX: 'scroll',
          overflowY: 'hidden',
        }}
      >
        {videos?.map((video, index) =>
          appLinks !== 'saathi' && isAndroid ? (
            <a
              href={`ulink://android.agrostar.in/internalWebpage?url=https://gold.agrostar.in/crop/${cropName}/video/${video?.youTubeId}?mobile=${mobile}&source=${source}&utm_source=as_embedded_app_croppage&title=${displayCropName}`}
              onClick={() => {
                CropInfoService.ping();
                ClevertapService.pushEvent(`Card Clicked`, {
                  Type: 'Play Gold Video',
                  'Video Id': video?.youTubeId,
                });
              }}
            >
              <GoldVideoCard video={video} />
            </a>
          ) : (
            <Grid
              item
              key={index}
              onClick={() => handleVideoClick(video, cropName)}
            >
              <GoldVideoCard video={video} />
            </Grid>
          ),
        )}

        {videos?.length === 0 && (
          <Grid item sx={{ height: '150px', maxHeight: '150px' }}>
            <ServerError />
          </Grid>
        )}
      </Grid>

      {appLinks !== 'saathi' && isAndroid ? (
        <ViewAllButton
          variant="text"
          endIcon={<East />}
          href={`ulink://android.agrostar.in/internalWebpage?url=https://gold.agrostar.in/crop-videos/${cropName}?mobile=${mobile}&source=${source}&utm_source=as_embedded_app_croppage&title=${displayCropName}`}
          onClick={() =>
            ClevertapService.pushEvent(`Button Clicked`, {
              Type: 'View All Gold Videos',
            })
          }
          disabled={videos?.length === 0}
        >
          <FormattedMessage id="label_view_all_videos" />
        </ViewAllButton>
      ) : (
        <ViewAllButton
          variant="text"
          endIcon={<East />}
          onClick={() => handleViewAllClick(cropName)}
          disabled={videos?.length === 0}
        >
          <FormattedMessage id="label_view_all_videos" />
        </ViewAllButton>
      )}
    </>
  );
}

function GoldVideoCard({ video }) {
  // useEffect(() => {
  //   const videos = document.querySelectorAll('video');
  //   videos?.forEach((video) => {
  //     // We can only control playback without interaction if video is mute
  //     video.muted = true;
  //     // Play is a promise so we need to check if we have it
  //     const playPromise = video.play();
  //     if (playPromise !== undefined) {
  //       playPromise.then((_) => {
  //         const observer = new IntersectionObserver(
  //           (entries) => {
  //             entries.forEach((entry) => {
  //               if (entry.intersectionRatio === 1) {
  //                 video.play();
  //               } else {
  //                 video.pause();
  //               }
  //             });
  //           },
  //           { threshold: 1 },
  //         );
  //         observer.observe(video);
  //       });
  //     }
  //   });
  // }, []);

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: '8px',
        position: 'relative',
        height: '100%',
        maxWidth: '160px',
      }}
    >
      <video
        loop
        muted
        autoPlay
        playsInline
        width="160px"
        height="90px"
        style={{
          background: '#000',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
        poster={`https://i.ytimg.com/vi/${video?.youTubeId}/default.jpg`}
      >
        <source
          src={`https://static.agrostar.in/videos/previews/${video?.youTubeId}.MP4.5s.mp4?tr=h-90`}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <PlayIcon />
      <CardContent sx={{ p: '4px 8px', pb: '5px !important' }}>
        <VideoViewsWrapper>
          <VisibilityOutlined
            sx={{ width: '20px', height: '20px', mr: '3px' }}
          />
          <span>{video?.views}</span>
        </VideoViewsWrapper>
        <Clamp lines={2} maxLines={2}>
          <Typography fontSize={14}>{video?.title}</Typography>
        </Clamp>
      </CardContent>
    </Card>
  );
}

ExpertVideos.fetchData = async (match, search) => {
  const { language, state, slug } = getRouteParams(match, search);

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    languageURLs[lang] = makeExpertVideosHref(match, search, slug, lang);
  });

  const stateURLs = {};
  allStates.forEach((state) => {
    if (state.shop) {
      const lang = language === 'en' ? 'en' : state.language;
      stateURLs[state.slug] = makeExpertVideosHref(
        match,
        search,
        slug,
        lang,
        state.slug,
      );
    } else {
      stateURLs[state.slug] = 'stateNa';
    }
  });

  return {
    initialData: {},
    titleId: 'label_crop_schedule',
    seoData: {},
    languageURLs,
    stateURLs,
    disableAnalytics: true,
  };
};

export default injectIntl(ExpertVideos);
