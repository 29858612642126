export const topLevelCategories = [
  'seeds',
  'crop-protection',
  'crop-nutrition',
  'hardware',
  'combo',
  'animal-husbandry',
];

export const categoryDetails = {
  seeds: {
    labelKey: 'label_seeds', // for strings
    value: 'seeds', // for API call
  },
  'crop-protection': {
    labelKey: 'label_protection',
    value: 'Crop Protection',
  },
  'crop-nutrition': {
    labelKey: 'label_nutrition',
    value: 'Crop Nutrition',
  },
  hardware: {
    labelKey: 'label_hardware',
    value: 'hardware',
  },
  combo: {
    labelKey: 'label_combo',
    value: 'Combo',
  },
  'animal-husbandry': {
    labelKey: 'label_animal_husbandry',
    value: 'Animal Husbandry',
  },
};
