import React, { useEffect, useState } from 'react';
import {
  createIntl,
  createIntlCache,
  FormattedMessage,
  injectIntl,
} from 'react-intl';

import { Box } from '@mui/system';
import { Button, Grid, Typography, Skeleton } from '@mui/material';
import { Directions, Call, ArrowForward } from '@mui/icons-material';
import { WhatsappShareButton } from 'react-share';
import {
  getRouteParams,
  makeSaathiStoreHref,
  makeShareableStoreHref,
} from '../utilities/links';
import store from '../utilities/store';
import { Loader } from '../components/Loader';
import { useFetchData } from '../services/useFetchData';
import {
  getFarmerWallet,
  getSaathiStore,
} from '../services/saathiStoreService';
import { allStates, getAppSource, getStateBySlug } from '../utilities/states';
import { getEnv } from '../utilities/getEnv';
import AndroidService from '../services/AndroidService';
import strings from '../utilities/strings';
import ServerError from '../components/ServerError';
import StoreInventory from './StoreInventory';
import ClevertapService from '../services/ClevertapService';
import StarIcon from '@mui/icons-material/Star';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { getPaginatedStoreInventory } from '../services';

const styles = {
  storeFrontWrapper: {
    position: 'relative',
    minHeight: '100vh',
    background: '#e5e5e5',
  },
  drawerWrapper: {
    position: 'relative',
    background: '#fff',
    paddingTop: '14px',
  },
  storeDetailWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: '16px',
    position: 'sticky',
    zIndex: 1, // This was done because of lottie animation going over this header when it is sticky while scrolling.
    margin: '0px 15px',
    padding: '10px 15px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 12px 24px rgba(28, 28, 28, 0.1)',
    border: '1px solid rgba(202, 202, 202, 0.3)',
  },
  storeAndShareWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: '5px',
  },
  shareIconDiv: {
    minWidth: '85px',
    height: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '7px',
    background: '#fff',
    border: '1px solid #00733E',
    borderRadius: '10px',
    textTransform: 'none',
  },
  share: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#00733E',
    marginLeft: '2px',
  },
  storeVisitWrapper: {
    display: 'flex',
    width: 'auto !important',
    height: '62px',
    padding: '10px',
    margin: '20px 15px',
    background:
      'linear-gradient(270deg, rgba(157, 33, 35, 0) 0%, rgba(157, 33, 35, 0.17) 50.86%, rgba(157, 33, 35, 0) 97.1%)',
    borderTop: '2px solid #9D2123',
    borderBottom: '2px solid #9D2123',
    justifyContent: 'center',
    alignItems: 'center',
  },
  callWrapper: {
    width: 'auto !important',
    height: '66px',
    padding: '10px',
    margin: '10px 15px 30px',
    background: 'rgba(0, 115, 62, 0.2)',
    border: '2px solid #00733E',
    borderRadius: '10px',
  },
  sectionBreak: {
    margin: '0 0 20px',
    border: '1px dashed #C7CAC9',
  },
  fixedWhatsAppIcon: {
    width: '62px',
    height: '62px',
    position: 'fixed',
    right: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '2',
    borderRadius: '50%',
    backdropFilter: 'blur(1.66667px)',
    background: 'rgba(76, 175, 80, 0.3)',
    boxShadow: '0px 8.33333px 8.33333px rgba(76, 175, 80, 0.2)',
  },
  fixedWhatsAppWebShare: {
    position: 'fixed',
    right: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '2',
    boxShadow: '0px 8.33333px 8.33333px rgba(76, 175, 80, 0.2)',
  },
  storeRating: {
    padding: '4px 3px',
    background: '#E5A705',
    borderRadius: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  storeLocation: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop: '10px',
  },
  bottomBannerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
  },
  bottomAgroStarPointRedemptionCtaWrapper: {
    display: 'flex',
    width: '100%',
    height: '70px',
    position: 'fixed',
    background: '#fff',
    justifyContent: 'center',
    alignItems: 'flex-end',
    zIndex: '2',
    bottom: '0',
    boxShadow: '0px -12px 24px rgba(28, 28, 28, 0.1)',
    padding: '10px 12px',
  },
  bottomAgroStarPointRedemptionCta: {
    display: 'flex',
    background: '#00733E',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

function StoreFront({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    StoreFront,
    store,
    setHeaderURLs,
    intl,
  );
  const [shareText, setShareText] = useState(null);
  const [isAndroid, setIsAndroid] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const { id, state, language, showHeader, farmerId, farmerAuthToken } =
    getRouteParams(match, search);
  const [agroStarPoints, setAgroStarPoints] = useState(0);
  const [storeInventoryResponse, setStoreInventoryResponse] = useState(null);

  useEffect(() => {
    setPageTitle();
    setShareText(intl?.formatMessage({ id: 'store_front_share_text' }));
    if (AndroidService.checkIfLatestVersion()) {
      setIsAndroid(true);
      ClevertapService.pushEvent('Store Front Viewed', {
        page: '/saathi-store',
        source: getAppSource(state),
        isAndroid: true,
      });
    } else {
      setIsAndroid(false);
      ClevertapService.pushEvent('Store Front Viewed', {
        page: '/saathi-store',
        source: getAppSource(state),
        isAndroid: false,
      });
    }
    getPaginatedInventory();
  }, []);

  useEffect(() => {
    if (farmerId != null && farmerAuthToken != null) {
      getFarmerWallet(farmerAuthToken, farmerId, language, state).then(
        (response) => {
          if (
            response?.responseData?.agrostarWallet?.agrostarPoints
              ?.amountRemaining
          ) {
            setAgroStarPoints(
              response?.responseData?.agrostarWallet?.agrostarPoints
                ?.amountRemaining,
            );
          }
        },
      );
    }
  }, [farmerId, farmerAuthToken]);

  const setPageTitle = () => {
    if (storeName) {
      document.title = `${intl?.formatMessage({
        id: 'label_lal_dukan',
      })}: ${storeName}`;
    }
  };

  const getPaginatedInventory = () => {
    setShowSkeleton(true);
    getPaginatedStoreInventory({ match, search, retailerId: id }).then(
      (response) => {
        setShowSkeleton(false);
        if (response?.responseData) {
          setStoreInventoryResponse(response?.responseData);
        }
      },
    );
  };

  const handleAndroidShare = () => {
    if (isAndroid) {
      handleShareClickEvent();
      AndroidService.shareOnWhatsApp(
        `${shareText}
👉 ${storeUrl}`,
        storeImage
          ? storeImage
          : `${getEnv('FWA_BASE_URL')}/icons/ogStoreImage${language === 'gu' ? 'GJ' : ''
          }.png`,
      );
    }
  };

  const handleShareClickEvent = () => {
    ClevertapService.pushEvent('Share Button Clicked', {
      page: '/saathi-store',
      source: getAppSource(state),
      isAndroid,
    });
  };

  const handleCallClickEvent = () => {
    ClevertapService.pushEvent('Call Button Clicked', {
      page: '/saathi-store',
      source: getAppSource(state),
      isAndroid,
    });
    const callLink = document.createElement('a');
    callLink.href = `tel:${phoneNumber}`;
    callLink.click();
  };

  const handleDirectionClickEvent = () => {
    ClevertapService.pushEvent('Directions Button Clicked', {
      page: '/saathi-store',
      source: getAppSource(state),
      isAndroid,
    });
  };

  const handleWhatsAppFabOnClick = () => {
    if (isAndroid) {
      handleAndroidShare();
    } else {
      handleShareClickEvent();
    }
  };

  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error' || !data || !data?.response) {
    return <ServerError textId="store_not_found_message" />;
  }

  const {
    storeImage,
    storeName,
    phoneNumber,
    storeAddress,
    farmerVisits,
    latitude,
    longitude,
    distance,
    partnerInventory,
    storeRating,
  } = data?.response?.store;
  const storeUrl = `${getEnv('FWA_BASE_URL')}${makeShareableStoreHref(
    search,
    storeName,
    id,
  )}`;

  return (
    <div style={styles.storeFrontWrapper}>
      {isAndroid ? (
        <div
          style={{
            ...styles.fixedWhatsAppIcon,
            bottom: agroStarPoints ? '90px' : '30px',
          }}
          onClick={handleWhatsAppFabOnClick}
        >
          <img
            style={{ width: '32px', height: '32px' }}
            src={`${getEnv('FWA_BASE_URL')}/icons/whatsapp.svg`}
          />
        </div>
      ) : (
        <WhatsappShareButton
          style={{
            ...styles.fixedWhatsAppIcon,
            bottom: agroStarPoints ? '90px' : '30px',
          }}
          url={`
👉 ${storeUrl}`}
          title={shareText}
          onClick={handleShareClickEvent}
        >
          <img
            style={{ width: '32px', height: '32px' }}
            src={`${getEnv('FWA_BASE_URL')}/icons/whatsapp.svg`}
          />
        </WhatsappShareButton>
      )}
      {agroStarPoints > 0 && (
        <Box
          className="123"
          style={styles.bottomAgroStarPointRedemptionCtaWrapper}
        >
          <div
            style={styles.bottomAgroStarPointRedemptionCta}
            onClick={() => {
              location.href = 'ulink://android.agrostar.in/home?tabName=GOLD';
            }}
          >
            <div style={{ padding: '12px' }}>
              <Typography
                variant="h6"
                fontSize={12}
                fontWeight={700}
                color={'white'}
              >
                <img
                  src="/ic_agrostar_points.svg"
                  style={{ verticalAlign: 'middle' }}
                />{' '}
                {agroStarPoints}
              </Typography>
              <Typography variant="h6" fontSize={12} color={'white'}>
                <FormattedMessage id="label_you_have_agrostar_points" />
              </Typography>
            </div>
            <div style={{ padding: '12px' }}>
              <Typography variant="h6" fontSize={14} color={'white'}>
                <FormattedMessage id="label_use_points" />{' '}
                <ArrowForward sx={{ verticalAlign: 'bottom' }} />
              </Typography>
            </div>
          </div>
        </Box>
      )}
      {/* <StoreCarousel medias={storeImage} /> */}
      <div
        style={{
          height: storeImage ? '250px' : '',
          maxHeight: storeImage ? '250px' : '',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
      >
        {!storeImage ? (
          <img
            src={`${getEnv('FWA_BASE_URL')}/icons/ogStoreImage${language === 'gu' ? 'GJ' : ''
              }.png`}
            alt="Agrostar Store"
            width="100%"
          />
        ) : (
          <img
            src={`https://res.cloudinary.com/agrostar/image/fetch/c_fill,g_auto,q_auto,f_auto,h_900,w_1600/${storeImage}`}
            alt={`store-photo`}
            width="100%"
          />
        )}
      </div>
      <Box
        style={{
          ...styles.drawerWrapper,
          marginBottom: agroStarPoints ? '70px' : '0',
          top: storeImage ? '-25px' : '0',
        }}
      >
        <div
          style={{
            ...styles.storeDetailWrapper,
            top: showHeader === 'false' ? 0 : '110px',
          }}
        >
          <div style={{ width: '100%' }}>
            <div style={styles.storeAndShareWrapper}>
              <div>
                <Typography
                  variant="h6"
                  fontSize={16}
                  fontWeight={700}
                  sx={{
                    lineHeight: '1.2',
                    textTransform: 'capitalize',
                  }}
                >
                  {storeName}
                </Typography>
              </div>
              {storeRating &&
                storeRating >
                0(
                  <div style={styles.storeRating}>
                    <Typography
                      variant="subtitle2"
                      fontSize={12}
                      fontWeight={700}
                      color="#fff"
                      display="flex"
                      alignItems="center"
                      sx={{ lineHeight: '1' }}
                      whiteSpace="pre-wrap"
                    >
                      {storeRating} <StarIcon sx={{ fontSize: '12px' }} />
                    </Typography>
                  </div>,
                )}
            </div>

            <div style={styles.storeLocation}>
              <PlaceOutlinedIcon sx={{ color: 'GrayText' }} />
              <Typography
                variant="subtitle2"
                fontSize={12}
                sx={{ lineHeight: '1.2', color: '#666', ml: '5px' }}
              >
                {storeAddress}
              </Typography>
            </div>

            <Grid container justifyContent="start" sx={{ padding: '10px 0' }}>
              <Grid item xs={5.2} sx={{ 'a:visited': { color: '#00733E' } }}>
                <Button
                  href={`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`}
                  target="_blank"
                  startIcon={<Directions />}
                  sx={{
                    width: '100%',
                    height: '30px',
                    fontSize: '12px',
                    fontWeight: '700',
                    color: '#00733E',
                    background: '#FFF',
                    border: '1px solid #00733E',
                    '&:hover': { color: '#00733E', background: '#FFF' },
                    borderRadius: '10px',
                  }}
                  onClick={handleDirectionClickEvent}
                >
                  {Math.round(distance) ? (
                    <>
                      {Math.round(distance)} <FormattedMessage id="label_KM" />
                    </>
                  ) : (
                    <FormattedMessage id="label_direction" />
                  )}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  href={`tel:${phoneNumber}`}
                  startIcon={<Call />}
                  sx={{
                    width: '100%',
                    height: '30px',
                    ml: 2,
                    fontSize: '12px',
                    fontWeight: '700',
                    color: '#FFF',
                    background: '#00733E',
                    border: '1px solid #00733E',
                    '&:hover': { color: '#FFF', background: '#00733E' },
                    borderRadius: '10px',
                  }}
                  onClick={handleCallClickEvent}
                >
                  <FormattedMessage id="label_missed_call" />
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        {farmerVisits > 0 && (
          <div style={styles.storeVisitWrapper}>
            <Typography
              variant="subtitle2"
              fontSize={14}
              fontWeight={700}
              sx={{ span: { color: '#9D2123' } }}
            >
              <span>{farmerVisits}</span>{' '}
              <FormattedMessage id="label_farmer_visits" />
            </Typography>
          </div>
        )}
        {showSkeleton ? (
          <ProductSkeleton />
        ) : (
          storeInventoryResponse?.productGists?.length > 0 && (
            <StoreInventory
              inventoryResponse={storeInventoryResponse}
              retailerId={id}
              match={match}
              search={search}
            />
          )
        )}

        <div style={styles.bottomBannerWrapper}>
          <img src={`/ic_discount_store_man.svg`} />
          <Typography
            variant="subtitle2"
            fontSize={28}
            fontWeight={700}
            color={'GrayText'}
            sx={{ ml: 5 }}
          >
            <FormattedMessage id="label_discount_at_store" />
          </Typography>
        </div>
      </Box>
    </div>
  );
}

export function ProductSkeleton() {
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        sx={{ mt: 4, mb: 1, ml: 2, mr: 2 }}
      >
        <Grid item xs={5.5}>
          <Skeleton
            variant="rectangular"
            sx={{ height: '205px', borderRadius: '8px' }}
          />
        </Grid>
        <Grid item xs={5.5}>
          <Skeleton
            variant="rectangular"
            sx={{ height: '205px', borderRadius: '8px' }}
          />
        </Grid>
      </Grid>
    </>
  );
}

StoreFront.fetchData = async (match, search) => {
  const { id, language, state } = getRouteParams(match, search);
  const intlCache = createIntlCache();
  const intl = createIntl(
    {
      locale: 'en',
      messages: strings[language],
    },
    intlCache,
  );

  const [storeResponse] = await Promise.all([getSaathiStore(id, state)]);
  if (!storeResponse?.status) {
    return `saathi store not found`;
  }

  const { storeName, storeImage } = storeResponse?.responseData[0];
  const initialData = {
    response: {
      store: storeResponse?.responseData[0],
    },
  };
  const title = `${intl?.formatMessage({
    id: 'label_lal_dukan',
  })}: ${storeName}`;

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    languageURLs[lang] = makeSaathiStoreHref(
      match,
      search,
      storeName,
      id,
      lang,
    );
  });

  const stateURLs = {};
  allStates.forEach((state) => {
    if (state.shop) {
      const lang = language === 'en' ? 'en' : state.language;
      stateURLs[state.slug] = makeSaathiStoreHref(
        match,
        search,
        storeName,
        id,
        lang,
        state.slug,
      );
    } else {
      stateURLs[state.slug] = 'stateNa';
    }
  });

  const ogImage = storeImage
    ? `https://res.cloudinary.com/agrostar/image/fetch/c_fill,g_auto,q_auto,f_auto,h_630,w_1200/${storeImage}`
    : `${getEnv('FWA_BASE_URL')}/icons/ogStoreImage${language === 'gu' ? 'GJ' : ''
    }.png`;

  return {
    initialData,
    title,
    seoData: {
      ogTags: {
        'og:title': title,
        'og:description': storeName,
        'og:image': ogImage,
        'og:image:secure_url': ogImage,
        'og:image:width': 100,
        'og:image:height': 100,
      },
    },
    languageURLs,
    stateURLs,
    disableAnalytics: true,
  };
};

export default injectIntl(StoreFront);
