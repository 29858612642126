import { getEnv } from '../utilities/getEnv';
import { getAppSource } from '../utilities/states';
import AndroidService from './AndroidService';

async function initializeClevertap() {
  const clevertap = await import('clevertap-web-sdk');
  clevertap.default.init(getEnv('FWA_CLEVERTAP_KEY'));
  return clevertap.default;
}

export default class ClevertapService {
  static async pushProfile(farmerId, source, district, clevertap) {
    clevertap.onUserLogin.push({
      Site: {
        Identity: farmerId,
        Source: source,
        District: district,
      },
    });
  }
  static async pushEventWithTryCatch(eventName, eventData) {
    const clevertap = await initializeClevertap();
    if (!eventData) {
      eventData = {};
    }

    const { search, pathname } = window.location;
    const query = new URLSearchParams(search);

    const pinCode = localStorage?.getItem('pinCode');
    const farmerId = localStorage?.getItem(`farmerId`);
    const district = localStorage?.getItem(`district`);
    const latitude = localStorage?.getItem('latitude');
    const longitude = localStorage?.getItem('longitude');
    const source = localStorage?.getItem('source');
    const role = localStorage?.getItem('role');
    const farmerMobileNumber = query.get('mobile');

    if (pinCode) eventData['PinCode'] = pinCode;
    if (latitude) eventData['Latitude'] = latitude;
    if (district) eventData['District'] = district;
    if (farmerId) eventData['Farmer ID'] = farmerId;
    if (longitude) eventData['Longitude'] = longitude;
    if (role === 'FAG') {
      eventData['Role'] = 'fieldAgent';
      eventData['Field Agent Mobile Number'] = localStorage?.getItem(
        'fieldAgentMobileNumber',
      );
    }
    if (!eventData.page) {
      const page = pathname.split('/').filter((p) => p !== '');
      eventData['Page'] =
        page.length > 1 ? `/${page[0]}/${page[1]}` : `/${page[0]}`;
    }

    eventData['Source'] = source || getAppSource(query.get('state'));
    eventData['Is Android'] = AndroidService.checkIfAndroid();
    eventData['Time Stamp'] = new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'full',
      timeStyle: 'long',
    }).format(Date.now());

    console.info('Event: ', eventName, eventData);
    try {
      if (farmerId !== undefined) {
        ClevertapService.pushProfile(farmerId, source, district, clevertap);
      }
    } catch (error) {
      console.log(error);
    }
    try {
      if (AndroidService.checkIfAndroid()) {
        let appVersionCode = Android.getAppVersionCode();
        eventData['App Version Code'] = appVersionCode;
        let appversionName = Android.getAppVersion();
        eventData['App Version'] = appversionName;

        if (appVersionCode > 205) {
          AndroidService.pushEvent(eventName, JSON.stringify(eventData));
        } else {
          clevertap.event.push(eventName, eventData);
        }
      } else {
        clevertap.event.push(eventName, eventData);
      }
    } catch (error) {
      console.log('no android found');
      try {
        clevertap.event.push(eventName, eventData);
      } catch (error) {
        console.log('Error :', error);
      }
    }
  }

  static pushEvent = (eventName, eventData) => {
    if (eventName) {
      try {
        ClevertapService.pushEventWithTryCatch(eventName, eventData);
      } catch (err) {
        console.log(`failed to send analytics event for ${eventName}`);
        console.log(err);
      }
    }
  };
}
