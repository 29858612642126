import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Clamp from 'react-multiline-clamp';
import { FormattedMessage } from 'react-intl';

import { Box } from '@mui/system';
import { East, Flaky, LocalMallRounded, Report } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';

import styles from './styles';
import slugify from '../utilities/slugify';
import AndroidService from '../services/AndroidService';
import ClevertapService from '../services/ClevertapService';
import { TaskDetailAccordianSection } from '../components/AccordionTemplate';
import {
  getRouteParams,
  makeFarmerAppProductHref,
  makeProductHref,
} from '../utilities/links';

const DEFAULT_SOWING_METHOD = {
  hi: 'बुवाई',
  gu: 'વાવણી',
  mr: 'पेरणी',
  en: 'sowing',
};

export default function TaskCard({ index, task, match, search, showFullTask }) {
  const [showToast, setShowToast] = useState(false);
  const { state, language } = getRouteParams(match, search);

  // task performing duration string label
  let taskDurationLabel = 'label_task_duration_';
  const startDay = task?.startDay;
  const endDay = task?.endDay;
  if (startDay === endDay && startDay < 0) {
    taskDurationLabel += 'start_end_same_and_before_sowing_day';
  } else if (startDay === endDay && startDay === 0) {
    taskDurationLabel += 'start_end_same_and_on_sowing_day';
  } else if (startDay === endDay && startDay > 0) {
    taskDurationLabel += 'start_end_same_and_after_sowing_day';
  } else if (startDay < 0 && endDay < 0) {
    taskDurationLabel += 'start_before_end_before_sowing_day';
  } else if (startDay < 0 && endDay === 0) {
    taskDurationLabel += 'start_before_end_on_sowing_day';
  } else if (startDay < 0 && endDay > 0) {
    taskDurationLabel += 'start_before_end_after_sowing_day';
  } else if (startDay === 0 && endDay > 0) {
    taskDurationLabel += 'start_on_end_after_sowing_day';
  } else {
    taskDurationLabel += 'start_after_end_after_sowing_day';
  }

  return (
    <Card
      elevation={0}
      style={{
        ...styles.taskCard,
        boxShadow: showFullTask ? '0px 0px 2px rgb(0,0,0,0.25)' : '',
      }}
      id={index === 0 ? 'allcrops' : task?.taskStatus}
    >
      {index === 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '15px 15px 0',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <img
              src={
                task?.cropImageUrl ? task?.cropImageUrl : '/icons/agrostar.png'
              }
              alt={task?.cropName}
              width="35px"
              height="35px"
              style={{ borderRadius: '50%', marginRight: '10px' }}
            />
            <Typography fontSize={18} fontWeight={700}>
              {task?.cropName}
            </Typography>
          </div>
          <East sx={{ opacity: 0.6 }} />
        </Box>
      )}

      <div style={styles.titleFlex}>
        <Typography fontSize={14}>
          <FormattedMessage
            id={taskDurationLabel}
            values={{
              startDate: task?.startDate,
              endDate: task?.endDate,
              cropAgeStart: Math.abs(startDay),
              cropAgeEnd: Math.abs(endDay),
              sowingMethod:
                task?.sowingMethod || DEFAULT_SOWING_METHOD[language],
            }}
          />
        </Typography>
        {task?.important && (
          <Box sx={styles.importantTaskIdentifier}>
            <FormattedMessage id="label_important" />
          </Box>
        )}
      </div>

      <Typography fontSize={16} fontWeight={700} sx={{ padding: '8px 15px 0' }}>
        {task?.title}
      </Typography>

      {showFullTask && (
        <>
          {task?.videoUrl ? (
            <div style={{ padding: '8px 15px 0' }}>
              <Box sx={styles.videoWrapper}>
                <div className="video">
                  <ReactPlayer
                    url={task?.videoUrl}
                    allowFullScreen
                    controls={true}
                    width="100%"
                    height="100%"
                    onEnded={() => {
                      ClevertapService.pushEvent(`Task Video Playback Ended`, {
                        Type: 'Crop Schedule',
                        'Video Url': task?.videoUrl,
                      });
                    }}
                    onPlay={() => {
                      ClevertapService.pushEvent(
                        `Task Video Playback Started`,
                        {
                          Type: 'Crop Schedule',
                          'Video Url': task?.videoUrl,
                        },
                      );
                    }}
                    onPause={() => {
                      ClevertapService.pushEvent(`Task Video Playback Paused`, {
                        Type: 'Crop Schedule',
                        'Video Url': task?.videoUrl,
                      });
                    }}
                  />
                </div>
              </Box>
            </div>
          ) : (
            task?.imageUrl &&
            !task?.imageUrl.includes('drive.google.com') && (
              <div style={{ padding: '8px 15px 0' }}>
                <CardMedia
                  component="img"
                  image={task?.imageUrl + '?tr=w-300,h-170'}
                  alt="task"
                  sx={{ borderRadius: '8px' }}
                />
              </div>
            )
          )}
        </>
      )}

      <CardContent sx={{ padding: '10px 15px 0' }}>
        <Clamp
          lines={3}
          maxLines={10}
          withToggle
          showMoreElement={({ toggle }) => (
            <span onClick={toggle} style={styles.readMore}>
              <FormattedMessage id="label_read_more" />
            </span>
          )}
          showLessElement={({ toggle }) => (
            <span onClick={toggle} style={styles.readMore}>
              <FormattedMessage id="label_read_less" />
            </span>
          )}
        >
          <Typography sx={{ fontSize: '14px', color: '#666' }}>
            {task?.methodology}
          </Typography>
        </Clamp>

        {showFullTask && task?.products?.length ? (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              gap="10px"
              sx={{ mt: 1.5 }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={styles.iconWrapper}>
                  <LocalMallRounded style={styles.iconStyles} />
                </div>
                <Typography fontSize={15} sx={{ ml: 1 }}>
                  <FormattedMessage id="label_needed_products" />
                </Typography>
              </Grid>
              {task?.products?.map((product, index) =>
                product?.name ? (
                  product?.sku ? (
                    <a
                      key={index}
                      href={
                        AndroidService.checkIfAndroid()
                          ? makeFarmerAppProductHref(product?.sku)
                          : makeProductHref(
                              match,
                              search,
                              slugify(product?.name || ''),
                              product?.sku,
                              language,
                              state,
                            ) + '&showHeader=false'
                      }
                      onClick={() =>
                        ClevertapService.pushEvent(`Card Clicked`, {
                          Type: 'Product',
                          SkuCode: product?.sku,
                          'Product Name': product?.name,
                        })
                      }
                      style={{ width: '100%' }}
                    >
                      <TaskProductCard product={product} />
                    </a>
                  ) : (
                    <div
                      key={index}
                      style={{ width: '100%' }}
                      onClick={() => setShowToast(true)}
                    >
                      <TaskProductCard product={product} />
                    </div>
                  )
                ) : (
                  ''
                ),
              )}
            </Grid>
            <Snackbar
              open={showToast}
              autoHideDuration={2000}
              onClose={() => setShowToast(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              message={<FormattedMessage id="label_product_not_available" />}
            />
          </>
        ) : (
          ''
        )}
      </CardContent>

      <div style={{ marginTop: '10px' }}></div>
      {showFullTask && (
        <>
          {task?.dosAndDonts?.length && (
            <TaskDetailAccordianSection
              icon={<Flaky style={styles.iconStyles} />}
              title={<FormattedMessage id="label_dos_and_dont" />}
              subText={
                <ul style={styles.dosAndDonts}>
                  {task?.dosAndDonts?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              }
            />
          )}

          {task?.impact && (
            <TaskDetailAccordianSection
              icon={
                <img
                  src="/icons/compost.svg"
                  alt="compost"
                  width="16px"
                  height="16px"
                />
              }
              title={<FormattedMessage id="label_step_benefit" />}
              subText={task?.impact}
            />
          )}

          {task?.impactIfNotDone && (
            <TaskDetailAccordianSection
              icon={<Report style={styles.iconStyles} />}
              title={<FormattedMessage id="label_step_missed_effect" />}
              subText={task?.impactIfNotDone}
            />
          )}
        </>
      )}
    </Card>
  );
}

function TaskProductCard({ product }) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: '8px',
        padding: '8px',
        background: '#fff',
        borderRadius: '8px',
        border: '1px solid #eee',
        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
      }}
    >
      <img
        src={
          product?.productImage
            ? product?.productImage + '?tr=h-150'
            : '/icons/agrostar.png'
        }
        alt="product"
        width="50px"
        height="62px"
      />
      <div>
        <Clamp lines={2} maxLines={2}>
          <Typography fontSize={14} sx={{ textAlign: 'left', m: '0 0 5px' }}>
            {product?.name}
          </Typography>
        </Clamp>

        {product?.dosage && (
          <Typography fontSize={14} sx={{ textAlign: 'left' }}>
            <b>
              <FormattedMessage id="label_dosage" />
              {': '}
            </b>
            {product?.dosage}
          </Typography>
        )}
      </div>
    </Box>
  );
}
