import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import store from '../utilities/store';
import useQuery from '../utilities/useQuery';
import { CropImage } from './SelectYourCrops';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import { getLocalDateString } from '../utilities/languages';
import { allStates, getStateBySlug } from '../utilities/states';
import CropInfoService from '../services/CropInfoService';
import { getRouteParams, makeCropAttributePageHref } from '../utilities/links';
import ClevertapService from '../services/ClevertapService';
import dayjs from 'dayjs';
import moment from 'moment';

const PageWrapper = styled('div')({
  minHeight: '100vh',
  padding: '16px 16px 20px',
  background: '#fff',
});
const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
});

function CropAttributes({ setHeaderURLs, intl }) {
  const { loadingStatus, match, search } = useFetchData(
    CropAttributes,
    store,
    setHeaderURLs,
    intl,
  );
  const query = useQuery();
  const history = useHistory();
  const userId = query.get('userId');
  const [crop, setCrop] = useState(null);
  const [open, setOpen] = useState(false);
  const [sowingDateInputNeeded, setSowingDateInputNeeded] = useState(null);
  const [loading, setLoading] = useState('started');
  const [attributes, setAttributes] = useState(null);
  const [cropInputs, setCropInputs] = useState(null);
  const farmerAuthToken = query.get('farmerAuthToken');
  const [sowingDateRange, setSowingDateRange] = useState({});
  const { slug, language, state } = getRouteParams(match, search);

  useEffect(() => {
    ClevertapService.pushEvent(`Page Viewed`, {
      Type: 'Crop Attributes',
      'Crop Name': slug,
    });
    (async function () {
      setLoading('started');
      try {
        const myCropResponse = await CropInfoService.getMyCropsSchedule(
          language,
          farmerAuthToken,
        );
        myCropResponse?.responseData?.forEach((crop) => {
          if (crop?.name === slug) {
            setCrop(crop);
          }
        });
        const attributeResponse = await CropInfoService.getCropAttributes(
          slug,
          language,
          state,
          farmerAuthToken,
        );
        setAttributes(attributeResponse?.responseData);

        const cropInputData = {};
        attributeResponse?.responseData?.inputsNeeded?.forEach((attribute) => {
          cropInputData[attribute?.attribute?.name] =
            attribute?.choices?.[0]?.name;
        });
        setCropInputs(cropInputData);

        const userProfileDetails = await CropInfoService.getUserProfileDetails(
          state,
          userId,
          farmerAuthToken,
        );
        userProfileDetails?.responseData?.myCrops?.forEach((crop) => {
          if (crop?.value === slug) {
            setSowingDateRange((prev) => ({
              ...prev,
              sowingDateMillis: crop?.sowingDate || new Date().getTime(),
            }));
            crop?.attributes?.forEach((attribute) => {
              setCropInputs((prev) => ({
                ...prev,
                [attribute?.name]: attribute?.value,
              }));
            });
          }
        });

        setLoading('done');
      } catch (e) {
        setLoading('error');
        console.log('Failed to fetch attribute: ', e);
      }
    })();
  }, []);

  useEffect(() => {
    validateSowingDate();
  }, [cropInputs]);

  async function validateSowingDate() {
    try {
      if (cropInputs) {
        let areAllAttributesValid = true;
        let attributeParams = '';
        Object.keys(cropInputs || {})?.forEach((input) => {
          if (!cropInputs[input]) {
            areAllAttributesValid = false;
          } else {
            attributeParams += `&${input}=${cropInputs[input]}`;
          }
        });

        if (!areAllAttributesValid) return;

        const sowingDateResponse =
          await CropInfoService.getSowingDateValidation(
            slug,
            attributeParams,
            state,
            farmerAuthToken,
          );

        moment.locale(language);
        const sowingStartDateMillis =
          sowingDateResponse?.responseData?.[0]?.sowingStartDateMillis;
        const sowingEndDateMillis =
          sowingDateResponse?.responseData?.[0]?.sowingEndDateMillis;
        const startDate = `${getLocalDateString(
          sowingStartDateMillis,
          language,
          'day',
        )} ${moment(sowingStartDateMillis).format('MMMM')} ${getLocalDateString(
          sowingStartDateMillis,
          language,
          'year',
        )}`;
        const endDate = `${getLocalDateString(
          sowingEndDateMillis,
          language,
          'day',
        )} ${moment(sowingEndDateMillis).format('MMMM')} ${getLocalDateString(
          sowingEndDateMillis,
          language,
          'year',
        )}`;
        setSowingDateRange((prev) => ({
          ...prev,
          startDate,
          endDate,
          ...sowingDateResponse?.responseData?.[0],
        }));
      }
    } catch (e) {
      console.log('Failed to fetch sowing date range: ', e);
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCropInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleDate = (event) => {
    const sowingDateMillis = new Date(event?.$d).getTime();
    setSowingDateInputNeeded(false);
    setSowingDateRange((prev) => ({ ...prev, sowingDateMillis }));
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    if (
      !sowingDateRange?.sowingDateMillis ||
      sowingDateRange?.sowingDateMillis <
      sowingDateRange?.sowingStartDateMillis ||
      sowingDateRange?.sowingEndDateMillis < sowingDateRange?.sowingDateMillis
    ) {
      return setSowingDateInputNeeded(true);
    }

    try {
      setLoading('started');
      ClevertapService.pushEvent(`Button Clicked`, { Type: 'Submit' });
      const attributes = [];
      Object?.entries(cropInputs)?.forEach((attribute) => {
        attributes.push({ name: attribute[0], value: attribute[1] });
      });

      const payload = {
        attributes,
        cropName: slug,
        sowingDateMillis: sowingDateRange?.sowingDateMillis,
      };
      await CropInfoService.updateCropAttributes(payload, farmerAuthToken);
      setLoading('done');
      history.goBack();
    } catch (e) {
      setLoading('done');
      console.log('Failed to update attributes: ', e);
    }
  };

  if (loadingStatus === 'loading' || loading === 'started') return <Loader />;
  if (loadingStatus === 'error' || loading === 'error') return <ServerError />;

  return (
    <PageWrapper>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <CropImage src={crop?.imageUrl} alt={crop?.display} />
        <div>
          <Typography fontSize={18}>{crop?.display}</Typography>
          {sowingDateRange?.startDate && sowingDateRange?.endDate ? (
            <Typography fontSize={13} sx={{ color: '#666' }}>
              <FormattedMessage id="label_correct_sowing_date_range" />
            </Typography>
          ) : (
            ''
          )}
        </div>
      </Box>

      <Typography fontSize={18} sx={{ mt: 3, mb: 2 }}>
        <FormattedMessage id="label_select_below_options" />
      </Typography>

      <Form onSubmit={handleSubmit}>
        {attributes?.inputsNeeded?.map((attribute, index) => (
          <FormControl key={index} fullWidth>
            <InputLabel>{attribute?.attribute?.display}</InputLabel>
            <Select
              name={attribute?.attribute?.name}
              value={cropInputs[attribute?.attribute?.name] || ''}
              onChange={handleChange}
              label={attribute?.attribute?.display}
              required
            >
              {attribute?.choices?.map((choice, index) => (
                <MenuItem
                  key={choice?.name}
                  value={choice?.name}
                  selected={index === 0 ? true : false}
                >
                  {choice?.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}

        {attributes?.startingDateDisplay && (
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                open={open}
                onChange={handleDate}
                onClose={() => setOpen(false)}
                label={attributes?.startingDateDisplay}
                value={dayjs(sowingDateRange?.sowingDateMillis)}
                slotProps={{
                  textField: {
                    readOnly: true,
                    onClick: () => setOpen(true),
                  },
                }}
              />
            </LocalizationProvider>
            {sowingDateInputNeeded && (
              <Typography sx={{ color: '#9D2123', mt: '5px' }}>
                <FormattedMessage
                  id="label_please_select_sowing_date"
                  values={{
                    startDate: sowingDateRange?.startDate,
                    endDate: sowingDateRange?.endDate,
                  }}
                />
              </Typography>
            )}
          </FormControl>
        )}

        <Button
          type="submit"
          sx={{
            height: '50px',
            fontSize: '16px',
            fontWeight: '600',
            color: '#fff',
            borderRadius: '8px',
            background: '#00733E',
            '&:hover': { background: '#00733E' },
          }}
        >
          <FormattedMessage id="label_submit" />
        </Button>
      </Form>
    </PageWrapper>
  );
}

CropAttributes.fetchData = async (match, search) => {
  const { language, state, slug } = getRouteParams(match, search);

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    languageURLs[lang] = makeCropAttributePageHref({
      match,
      search,
      lang,
      state,
      crop: slug,
    });
  });

  const stateURLs = {};
  allStates.forEach((state) => {
    if (state.shop) {
      const lang = language === 'en' ? 'en' : state.language;
      stateURLs[state.slug] = makeCropAttributePageHref({
        match,
        search,
        lang,
        state: state.slug,
        crop: slug,
      });
    } else {
      stateURLs[state.slug] = 'stateNa';
    }
  });

  return {
    initialData: {},
    titleId: 'label_crop_info',
    seoData: {},
    languageURLs,
    stateURLs,
    disableAnalytics: true,
  };
};

export default injectIntl(CropAttributes);
