import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Clamp from 'react-multiline-clamp';

import { CloseRounded } from '@mui/icons-material';
import { Button, Chip, Grid, styled, Typography } from '@mui/material';

import store from '../utilities/store';
import { getCropsList } from './CropListPage';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import { allStates, getStateBySlug } from '../utilities/states';
import CropInfoService from '../services/CropInfoService';
import { getRouteParams, makeSelectYourCropsHref } from '../utilities/links';
import { useHistory } from 'react-router-dom';
import useQuery from '../utilities/useQuery';
import ClevertapService from '../services/ClevertapService';
import { Box } from '@mui/system';
import Toast from '../components/Toast';

const SelectYourCropsPageWrapper = styled('div')({
  paddingBottom: '60px',
  background: '#fff',
  minHeight: '100vh',
});
const SelectedCropCard = styled(Grid)({
  position: 'relative',
  minWidth: '60px',
  maxWidth: '60px',
  textAlign: 'center',
});
const CropCard = styled(Grid)(({ selectedcrops, crop }) => ({
  minWidth: '31%',
  maxWidth: '31%',
  textAlign: 'center',
  padding: '10px 5px',
  marginBottom: '10px',
  color: selectedcrops?.[crop?.id] ? '#fff' : '#333',
  background: selectedcrops?.[crop?.id] ? '#01733e' : '#f1f1f1',
  borderRadius: '5px',
}));
export const CropImage = styled('img')({
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  border: '2px solid #fff',
});
const CloseButton = styled(CloseRounded)({
  position: 'absolute',
  top: '-5px',
  right: '0',
  width: '22px',
  height: '22px',
  padding: '2px',
  color: '#fff',
  background: '#000',
  borderRadius: '50%',
});
const SubmitButton = styled(Button)({
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  height: '50px',
  fontSize: '16px',
  fontWeight: '600',
  borderRadius: 0,
  color: '#fff',
  background: '#00733E',
  '&:hover': { background: '#00733E' },
});

function SelectYourCrops({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    SelectYourCrops,
    store,
    setHeaderURLs,
    intl,
  );
  const query = useQuery();
  const history = useHistory();
  const userId = query.get('userId');
  const farmerAuthToken = query.get('farmerAuthToken');
  const farmerId = query.get('farmerId');
  const appLinks = query.get('appLinks');
  const role = query.get('role');
  const { state, language } = getRouteParams(match, search);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [loading, setLoading] = useState('started');
  const [selectedCrops, setSelectedCrops] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('allCrops');
  const [organisedCategoryList, setOrganisedCategoryList] = useState(null);

  useEffect(() => {
    ClevertapService.pushEvent(`Page Viewed`, {
      Type: 'Select Or Change Crop',
    });
    (async function () {
      try {
        const cropsResponse = await getCropsList(state, language);
        let myCropsResponse = {};
        if (appLinks === 'saathi') {
          myCropsResponse = await CropInfoService.getSaathiCrops(
            farmerAuthToken,
            farmerId,
            language,
          );
          myCropsResponse = myCropsResponse?.responseData?.map((crop) => ({
            ...crop,
            image: crop?.imageUrl,
          }));
        } else {
          myCropsResponse = await CropInfoService.getMyCrops(
            language,
            state,
            farmerId,
            farmerAuthToken,
          );
          myCropsResponse = myCropsResponse?.responseData?.crops;
        }

        const response = {
          ...cropsResponse?.responseData,
          myCrops: myCropsResponse,
        };

        const allCrops = [];
        setOrganisedCategoryList(() => ({}));
        response?.children?.forEach((category) => {
          allCrops.push(...category?.children);
          setOrganisedCategoryList((prev) => ({
            ...prev,
            [category?.id]: category,
          }));
        });
        setOrganisedCategoryList((prev) => ({
          allCrops: {
            id: 'allCrops',
            children: allCrops,
            display: <FormattedMessage id="label_all_crops" />,
          },
          ...prev,
        }));

        response?.myCrops?.forEach((crop) => {
          setSelectedCrops((prev) => ({
            ...prev,
            [crop?.id]: crop,
          }));
        });
        setLoading('done');
      } catch (e) {
        console.log('Error: ', e);
        setLoading('error');
      }
    })();
  }, []);

  const handleRemoveCrop = (crop) => {
    ClevertapService.pushEvent(`Crop Removed`, {
      'Crop Name': crop?.category,
      'Crop Id': crop?.id,
    });

    if (role === 'FAG') {
      setShowToast(true);
      return setToastMessage('You are not allowed to add/remove crop!');
    }

    const newCrops = {};
    Object.values(selectedCrops || {})?.forEach((item) => {
      if (item?.id !== crop?.id) {
        newCrops[item?.id] = item;
      }
    });

    if (appLinks === 'saathi') {
      if (Object.keys(newCrops).length === 0) {
        setToastMessage('');
        return setShowToast(true);
      }

      CropInfoService.removeSaathiCrops([crop?.id], farmerAuthToken)
        .then((response) => {
          if (response?.status) {
            console.log('crop removed');
            setSelectedCrops(newCrops);
          } else {
            setShowToast(true);
            return setToastMessage('Error removing crop!');
          }
        })
        .catch((error) => {
          console.log(`Error removing Crops: ${error}`);
        });
    } else {
      setSelectedCrops(newCrops);
    }
  };

  const handleSelectCrop = (crop) => {
    if (selectedCrops?.[crop?.id]) {
      handleRemoveCrop(crop);
    } else {
      ClevertapService.pushEvent(`Crop Selected`, {
        'Crop Name': crop?.category,
        'Crop Id': crop?.id,
      });

      if (role === 'FAG') {
        setShowToast(true);
        return setToastMessage('You are not allowed to add/remove crop!');
      }

      if (appLinks === 'saathi') {
        CropInfoService.addSaathiCrops([crop?.id], farmerAuthToken)
          .then((response) => {
            if (response?.status) {
              console.log('crop added');
              setSelectedCrops({ ...selectedCrops, [crop?.id]: crop });
            } else {
              setShowToast(true);
              return setToastMessage('Error adding crop!');
            }
          })
          .catch((error) => {
            console.log(`Error adding Crops: ${error}`);
          });
      } else {
        setSelectedCrops({ ...selectedCrops, [crop?.id]: crop });
      }
    }
  };

  const handleChangeCategory = (category) => {
    ClevertapService.pushEvent(`Category Changed`, {
      Category: category?.category,
      'Category Id': category?.id,
    });
    setSelectedCategory(category?.id);
  };

  const handleSubmit = async () => {
    if (Object.keys(selectedCrops || {})?.length === 0) {
      setToastMessage('');
      return setShowToast(true);
    }

    setLoading('started');
    const cropNames = Object.entries(selectedCrops || {}).reduce(
      (acc, curr) => {
        acc += (curr[1]?.name || curr[1]?.category) + ', ';
        return acc;
      },
      '',
    );
    ClevertapService.pushEvent(`Button Clicked`, {
      Type: 'Submit',
      Crops: cropNames,
    });
    const payload = {
      data: [
        {
          action: 'CHANGE_MYCROPS',
          data: Object.keys(selectedCrops || {})?.map((id) => ({ id })),
        },
      ],
    };
    try {
      if (appLinks !== 'saathi') {
        await CropInfoService.updateMyCrops(
          state,
          userId,
          farmerAuthToken,
          payload,
        );
      }
      setLoading('done');
      history.goBack();
    } catch (e) {
      setLoading('done');
      console.log('Failed to update my crops: ', e);
    }
  };

  if (loadingStatus === 'loading' || loading === 'started') return <Loader />;
  if (loadingStatus === 'error' || loading === 'error') return <ServerError />;

  return (
    <SelectYourCropsPageWrapper>
      <Toast open={showToast} setOpen={setShowToast} message={toastMessage} />

      <Box
        sx={{
          padding: '20px 16px 0',
          boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography fontSize={16} fontWeight={700}>
          {Object.keys(selectedCrops || {})?.length ? (
            <FormattedMessage id="label_choosen_crops" />
          ) : (
            <FormattedMessage id="label_choose_from_below_list" />
          )}
        </Typography>
        <Typography fontSize={14} sx={{ color: '#666', pb: 2 }}>
          {Object.keys(selectedCrops || {})?.length ? (
            ''
          ) : (
            <FormattedMessage id="label_see_choosen_crops_here" />
          )}
        </Typography>

        <Grid
          container
          gap="10px"
          flexWrap="nowrap"
          sx={{
            pt: Object.values(selectedCrops || {})?.length ? '5px' : '',
            overflowX: Object.values(selectedCrops || {})?.length
              ? 'scroll'
              : '',
          }}
        >
          {Object.values(selectedCrops || {})?.map((crop) => (
            <SelectedCropCard item key={crop?.id}>
              <CloseButton onClick={() => handleRemoveCrop(crop)} />
              <CropImage
                src={crop?.image}
                alt={crop?.display}
                style={{ border: '2px solid #9D2123' }}
              />
              <Clamp lines={1} maxLines={1}>
                <Typography fontSize={14}>{crop?.display}</Typography>
              </Clamp>
            </SelectedCropCard>
          ))}
        </Grid>
      </Box>

      <Grid
        container
        gap="10px"
        flexWrap="nowrap"
        sx={{ p: 2, pb: 1, overflowX: 'scroll', overflowY: 'hidden' }}
      >
        {Object.values(organisedCategoryList || {})?.map((category) => (
          <Chip
            key={category?.id}
            variant="outlined"
            label={category?.display}
            onClick={() => handleChangeCategory(category)}
            sx={{
              fontSize: '16px',
              border:
                category?.id === selectedCategory
                  ? '1px solid #00733E'
                  : '1px solid #ABADAF',
              background:
                category?.id === selectedCategory
                  ? 'rgba(0, 115, 62, 0.2) !important'
                  : '',
              '&:hover': {
                background:
                  category?.id === selectedCategory
                    ? 'rgba(0, 115, 62, 0.2) !important'
                    : '',
              },
            }}
          />
        ))}
      </Grid>

      <Grid container gap="3%" sx={{ p: 2 }}>
        {organisedCategoryList?.[selectedCategory]?.children?.map((crop) => (
          <CropCard
            item
            key={crop?.id}
            crop={crop}
            selectedcrops={selectedCrops}
            onClick={() => handleSelectCrop(crop)}
          >
            <CropImage src={crop?.image} alt={crop?.display} />
            <Clamp lines={2} maxLines={2}>
              <Typography fontSize={14}>{crop?.display}</Typography>
            </Clamp>
          </CropCard>
        ))}
      </Grid>

      <SubmitButton onClick={handleSubmit}>
        <FormattedMessage id="label_submit" />
      </SubmitButton>
    </SelectYourCropsPageWrapper>
  );
}

SelectYourCrops.fetchData = async (match, search) => {
  const { language, state } = getRouteParams(match, search);
  const query = new URLSearchParams(search);
  const farmerId = query.get('farmerId');
  const farmerAuthToken = query.get('farmerAuthToken');

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    languageURLs[lang] = `${makeSelectYourCropsHref(
      match,
      search,
      lang,
    )}&farmerId=${farmerId}&farmerAuthToken=${farmerAuthToken}`;
  });

  const stateURLs = {};
  allStates.forEach((state) => {
    if (state.shop) {
      const lang = language === 'en' ? 'en' : state.language;
      stateURLs[state.slug] = `${makeSelectYourCropsHref(
        match,
        search,
        lang,
        state.slug,
      )}&farmerId=${farmerId}&farmerAuthToken=${farmerAuthToken}`;
    } else {
      stateURLs[state.slug] = 'stateNa';
    }
  });

  return {
    initialData: {},
    titleId: 'label_select_your_crops',
    seoData: {},
    languageURLs,
    stateURLs,
    disableAnalytics: true,
  };
};

export default injectIntl(SelectYourCrops);
